<template>
	<div v-show="getType" class="rules">
		<van-nav-bar
			title="收益報表計算規則"
			left-arrow
			class="qjc-agent-navbar"
			@click-left="$router.back()"
		></van-nav-bar>
		
		<!-- 代理人 -->
		<div v-if="type == 1">
			<table class="qjc-fts-32" border="0" cellpadding="0" cellspacing="0">
				<tr>
					<td>直銷返利</td>
					<td class="qjc-fts-22">HKD $<span class="qjc-fts-32">100</span>/次</td>
				</tr>
				<tr>
					<td>一級下線</td>
					<td class="qjc-fts-22">HKD $<span class="qjc-fts-32">40</span>/次</td>
				</tr>
				<tr>
					<td>二級下線</td>
					<td class="qjc-fts-22">HKD $<span class="qjc-fts-32">20</span>/次</td>
				</tr>
				<tr>
					<td>三級下線</td>
					<td class="qjc-fts-22">HKD $<span class="qjc-fts-32">10</span>/次</td>
				</tr>
				<tr>
					<td>四級下線</td>
					<td class="qjc-fts-22">HKD $<span class="qjc-fts-32">5</span>/次</td>
				</tr>
			</table>
			
			<dl class="tips qjc-fts-30 qjc-texta-l">
				<dt class="qjc-fts-34">直銷收益</dt>
				<dd>
					體檢客戶購買時填寫代理人的邀請碼，成交後代理人預購次數-1，直銷收益+HKD $530，即預購的HKD $430全額返還，並獲得HKD $100的直銷返利。
				</dd>
				<dd>
					如果C端客戶購買時，填寫代理人的邀請碼，而該代理人剩餘預購次數為0，則：
					<p>1.體檢客戶依然享受HKD $530的優惠價</p>
					<p>2.該筆銷售不計入該代理人的直銷業績，也不能通過補預購獲得補償</p>
					<p>3.該代理人的上級代理人依然享受分成</p>
				</dd>
			</dl>
			
		</div>
		
		<!-- kol -->
		<div v-if="type == 2">
			<table class="qjc-fts-32" border="0" cellpadding="0" cellspacing="0">
				<tr>
					<td>代理人</td>
					<td class="qjc-fts-22"><span class="qjc-fts-32">收益/</span>成交次數</td>
				</tr>
				<tr>
					<td>一級下線</td>
					<td class="qjc-fts-22">HKD $<span class="qjc-fts-32">1</span>/次</td>
				</tr>
				<tr>
					<td>二級下線</td>
					<td class="qjc-fts-22">HKD $<span class="qjc-fts-32">1</span>/次</td>
				</tr>
				<tr>
					<td>三級下線</td>
					<td class="qjc-fts-22">HKD $<span class="qjc-fts-32">1</span>/次</td>
				</tr>
				<tr>
					<td>四級下線</td>
					<td class="qjc-fts-22">HKD $<span class="qjc-fts-32">1</span>/次</td>
				</tr>
				<tr>
					<td>五級下線</td>
					<td class="qjc-fts-22">HKD $<span class="qjc-fts-32">1</span>/次</td>
				</tr>
			</table>
			
			<dl class="tips qjc-fts-30 qjc-texta-l">
				<dd>
					KOL收益按單計算，即無論幾級下級代理人，按成交總單數計算，每單1元。
					<p>·KOL的下級代理人A預購20次，則KOL收益20元</p>
					<p>·KOL的下下級代理人B預購次數為0，但是C端客戶依舊使用了B的邀請碼購買成交，則KOL依然享受收益1元，而代理人B無收益。</p>
				</dd>
			</dl>
			
		</div>
		
		<!-- 机构 -->
		<div v-if="type == 3">
			<table class="qjc-fts-32" border="0" cellpadding="0" cellspacing="0">
				<tr>
					<td>直銷返利</td>
					<td class="qjc-fts-22">HKD $<span class="qjc-fts-32">100</span>/次</td>
				</tr>
				<tr>
					<td>員工下線</td>
					<td class="qjc-fts-22">HKD $<span class="qjc-fts-32">40</span>/次</td>
				</tr>
			</table>
			
			<dl class="tips qjc-fts-30 qjc-texta-l">
				<dt class="qjc-fts-34">直銷收益</dt>
				<dd>
					<p>體檢客戶購買時填寫機構的邀請碼，成交後機構直銷收益+HKD $100。</p>
					<p>體檢客戶購買時填寫員工的邀請碼，成交後機構分銷收益+HKD $40。</p>
				</dd>
			</dl>
			
		</div>
		
		<!-- 员工 -->
		<div v-if="type == 4">
			<dl style="margin-bottom: 0;" class="tips qjc-fts-30 qjc-texta-l">
				<dt class="qjc-fts-34">直銷收益</dt>
				<dd>
					體檢客戶購買時填寫您的邀請碼，成交後直銷收益+HKD $100。
				</dd>
			</dl>
			
			<table class="qjc-fts-32" border="0" cellpadding="0" cellspacing="0">
				<tr>
					<td>直銷返利</td>
					<td class="qjc-fts-22">HKD $<span class="qjc-fts-32">100</span>/次</td>
				</tr>
			</table>
			
		</div>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar } from 'vant'
	Vue.use(NavBar);
	
	import { agentInfo } from '@/js/axios'
	
	export default{
		name: 'agentEarningsRules',
		data() {
			return {
				type: '',
				getType: false
			}
		},
		mounted() {
			agentInfo().then(res => {
				if(res.code == 200){
					// 存储用户信息
					var userInfo = res.data; 
			
					this.type = userInfo.type;// 1.代理人 2.KOL 3.企业 4.员工
					this.getType = true;
			
				}else{
					Toast.fail(res.msg);
				}
			});
		}
	}
</script>

<style lang="scss" scoped>
	.rules{
		color: #3B4549;
		
		.qjc-fts-22{
			font-size: 0.22rem;
		}
		table{
			width: 6.86rem;
			border: 0.01rem solid #E1E3EB;
			border-radius: 0.12rem;
			line-height: 0.89rem;
			margin: 0.12rem auto;
			
			tr{
				&:last-child{
					td{
						border-bottom: 0;
					}
				}
				
				td{
					width: 50%;
					border-right: 0.01rem solid #E1E3EB;
					border-bottom: 0.01rem solid #E1E3EB;
					
					&:nth-child(2n){
						border-right: 0;
					}
				}
			}
		}
		.tips{
			width: 6.86rem;
			line-height: 0.46rem;
			margin: 0.64rem auto;
			
			dt{
				font-weight: 500;
				line-height: 0.34rem;
				margin-bottom: 0.16rem;
			}
			dd{
				margin-bottom: 1em;
			}
		}
	}
</style>
