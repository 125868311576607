var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getType),expression:"getType"}],staticClass:"rules"},[_c('van-nav-bar',{staticClass:"qjc-agent-navbar",attrs:{"title":"收益報表計算規則","left-arrow":""},on:{"click-left":function($event){return _vm.$router.back()}}}),(_vm.type == 1)?_c('div',[_vm._m(0),_vm._m(1)]):_vm._e(),(_vm.type == 2)?_c('div',[_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.type == 3)?_c('div',[_vm._m(4),_vm._m(5)]):_vm._e(),(_vm.type == 4)?_c('div',[_vm._m(6),_vm._m(7)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"qjc-fts-32",attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',[_vm._v("直銷返利")]),_c('td',{staticClass:"qjc-fts-22"},[_vm._v("HKD $"),_c('span',{staticClass:"qjc-fts-32"},[_vm._v("100")]),_vm._v("/次")])]),_c('tr',[_c('td',[_vm._v("一級下線")]),_c('td',{staticClass:"qjc-fts-22"},[_vm._v("HKD $"),_c('span',{staticClass:"qjc-fts-32"},[_vm._v("40")]),_vm._v("/次")])]),_c('tr',[_c('td',[_vm._v("二級下線")]),_c('td',{staticClass:"qjc-fts-22"},[_vm._v("HKD $"),_c('span',{staticClass:"qjc-fts-32"},[_vm._v("20")]),_vm._v("/次")])]),_c('tr',[_c('td',[_vm._v("三級下線")]),_c('td',{staticClass:"qjc-fts-22"},[_vm._v("HKD $"),_c('span',{staticClass:"qjc-fts-32"},[_vm._v("10")]),_vm._v("/次")])]),_c('tr',[_c('td',[_vm._v("四級下線")]),_c('td',{staticClass:"qjc-fts-22"},[_vm._v("HKD $"),_c('span',{staticClass:"qjc-fts-32"},[_vm._v("5")]),_vm._v("/次")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"tips qjc-fts-30 qjc-texta-l"},[_c('dt',{staticClass:"qjc-fts-34"},[_vm._v("直銷收益")]),_c('dd',[_vm._v(" 體檢客戶購買時填寫代理人的邀請碼，成交後代理人預購次數-1，直銷收益+HKD $530，即預購的HKD $430全額返還，並獲得HKD $100的直銷返利。 ")]),_c('dd',[_vm._v(" 如果C端客戶購買時，填寫代理人的邀請碼，而該代理人剩餘預購次數為0，則： "),_c('p',[_vm._v("1.體檢客戶依然享受HKD $530的優惠價")]),_c('p',[_vm._v("2.該筆銷售不計入該代理人的直銷業績，也不能通過補預購獲得補償")]),_c('p',[_vm._v("3.該代理人的上級代理人依然享受分成")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"qjc-fts-32",attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',[_vm._v("代理人")]),_c('td',{staticClass:"qjc-fts-22"},[_c('span',{staticClass:"qjc-fts-32"},[_vm._v("收益/")]),_vm._v("成交次數")])]),_c('tr',[_c('td',[_vm._v("一級下線")]),_c('td',{staticClass:"qjc-fts-22"},[_vm._v("HKD $"),_c('span',{staticClass:"qjc-fts-32"},[_vm._v("1")]),_vm._v("/次")])]),_c('tr',[_c('td',[_vm._v("二級下線")]),_c('td',{staticClass:"qjc-fts-22"},[_vm._v("HKD $"),_c('span',{staticClass:"qjc-fts-32"},[_vm._v("1")]),_vm._v("/次")])]),_c('tr',[_c('td',[_vm._v("三級下線")]),_c('td',{staticClass:"qjc-fts-22"},[_vm._v("HKD $"),_c('span',{staticClass:"qjc-fts-32"},[_vm._v("1")]),_vm._v("/次")])]),_c('tr',[_c('td',[_vm._v("四級下線")]),_c('td',{staticClass:"qjc-fts-22"},[_vm._v("HKD $"),_c('span',{staticClass:"qjc-fts-32"},[_vm._v("1")]),_vm._v("/次")])]),_c('tr',[_c('td',[_vm._v("五級下線")]),_c('td',{staticClass:"qjc-fts-22"},[_vm._v("HKD $"),_c('span',{staticClass:"qjc-fts-32"},[_vm._v("1")]),_vm._v("/次")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"tips qjc-fts-30 qjc-texta-l"},[_c('dd',[_vm._v(" KOL收益按單計算，即無論幾級下級代理人，按成交總單數計算，每單1元。 "),_c('p',[_vm._v("·KOL的下級代理人A預購20次，則KOL收益20元")]),_c('p',[_vm._v("·KOL的下下級代理人B預購次數為0，但是C端客戶依舊使用了B的邀請碼購買成交，則KOL依然享受收益1元，而代理人B無收益。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"qjc-fts-32",attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',[_vm._v("直銷返利")]),_c('td',{staticClass:"qjc-fts-22"},[_vm._v("HKD $"),_c('span',{staticClass:"qjc-fts-32"},[_vm._v("100")]),_vm._v("/次")])]),_c('tr',[_c('td',[_vm._v("員工下線")]),_c('td',{staticClass:"qjc-fts-22"},[_vm._v("HKD $"),_c('span',{staticClass:"qjc-fts-32"},[_vm._v("40")]),_vm._v("/次")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"tips qjc-fts-30 qjc-texta-l"},[_c('dt',{staticClass:"qjc-fts-34"},[_vm._v("直銷收益")]),_c('dd',[_c('p',[_vm._v("體檢客戶購買時填寫機構的邀請碼，成交後機構直銷收益+HKD $100。")]),_c('p',[_vm._v("體檢客戶購買時填寫員工的邀請碼，成交後機構分銷收益+HKD $40。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"tips qjc-fts-30 qjc-texta-l",staticStyle:{"margin-bottom":"0"}},[_c('dt',{staticClass:"qjc-fts-34"},[_vm._v("直銷收益")]),_c('dd',[_vm._v(" 體檢客戶購買時填寫您的邀請碼，成交後直銷收益+HKD $100。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"qjc-fts-32",attrs:{"border":"0","cellpadding":"0","cellspacing":"0"}},[_c('tr',[_c('td',[_vm._v("直銷返利")]),_c('td',{staticClass:"qjc-fts-22"},[_vm._v("HKD $"),_c('span',{staticClass:"qjc-fts-32"},[_vm._v("100")]),_vm._v("/次")])])])
}]

export { render, staticRenderFns }